<template>
	<div class="supplier-retention-change">
		<en-table-layout
			:tableData="tableData.data"
			:loading="loading"
    >
      <template slot="toolbar">
        <div class="toolbar-title">
          供应商变更记录
        </div>
      </template>

			<template slot="table-columns">
				<el-table-column label="编号ID" prop="id"></el-table-column>
				<el-table-column label="变动金额" prop="price"></el-table-column>
				<el-table-column label="剩余质保金额" prop="res_price"></el-table-column>
				<el-table-column label="变动备注" prop="reamrk"></el-table-column>
				<el-table-column label="变动时间">
					<template slot-scope="scope">
						{{scope.row.create_time | parseTime}}
					</template>
				</el-table-column>
				<el-table-column label="操作人" prop="creator" >
				</el-table-column>
			</template>
			<el-pagination
				slot="pagination"
				@size-change="handlePageSizeChange"
				@current-change="handlePageCurrentChange"
				:current-page="tableData.page_no"
				:page-size="tableData.page_size"
        :page-sizes="MixinPageSizes"
				:layout="MixinTableLayout"
        background
				:total="tableData.data_total">
			</el-pagination>
		</en-table-layout>

		<!--审核商品 dialog-->
		<el-dialog
			title="发送站内信"
			:visible.sync="dialogGoodsAuditVisible"
			width="400px"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
		>
			<el-form :model="goodsAuditForm" :rules="goodsAuditRules" ref="goodsAuditForm" label-width="100px">
				<!--是否通过=-->
				<el-form-item label="发送对象" prop="ca_status">
					<span>50个对象</span>
				</el-form-item>
				<el-form-item label="标题" prop="ca_status">
					<el-input
						type="text"
						:maxlength="20"
						v-model="goodsAuditForm.comment">
					</el-input>
				</el-form-item>
				<el-form-item label="内容" prop="comment">
					<el-input
						type="textarea"
						:autosize="{ minRows: 2, maxRows: 4}"
						placeholder="请输入审核备注(120字以内)"
						:maxlength="120"
						v-model="goodsAuditForm.comment">
					</el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogGoodsAuditVisible = false" size="small">取 消</el-button>
				<el-button type="primary" @click="submitGoodsAuditForm" size="small">确 定</el-button>
			</span>
		</el-dialog>
		<!-- 添加供应商 -->
		<el-dialog
			title="添加/编辑"
			:visible.sync="dialogGood"
			width="500px"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			>
			<el-form :model="gooddata" :rules="gooddataRules" ref="gooddata" label-width="120px">
				<!--是否通过=-->
				<el-form-item label="供应商名称：" prop="shop_name">
					<el-input
						type="text"
						:maxlength="20"
						v-model="gooddata.shop_name">
					</el-input>
				</el-form-item>
				<el-form-item label="供应商账号：" prop="mobile">
					<el-input
						type="text"
						:maxlength="20"
						v-model="gooddata.mobile">
					</el-input>
				</el-form-item>
				<el-form-item label="供应商密码：" prop="shop_name">
					<el-input
						type="text"
						:maxlength="20"
						v-model="gooddata.shop_name">
					</el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogGood = false" size="small">取 消</el-button>
				<el-button type="primary" @click="submitGoodsAuditForm" size="small">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import * as API_shop from '@/api/supplier'
	import { handleDownload } from '@/utils'
	import { Foundation } from '@/../ui-utils'
  import EnTableLayout from "../../../ui-components/TableLayout/src/main";
	export default {
		name: 'retentionChange',
    components: {EnTableLayout},
    data() {

			return {
				//供应商添加
				gooddata:{
					shop_name:'',
					mobile:'',
					mobile:''
				},

				value:null,
				ca_statusList: [ '待审核', '审核中', '已结算', '不通过' ],

				/** 列表loading状态 */
				loading: false,
				importLoading: false,
				idsList: [],

				/** 列表参数 */
				params: {
					page_no: 1,
					page_size: 20,
					shop_id: this.$route.params.id
				},

				daterangeDate: '',

				/** 列表数据 */
				tableData: {},

				/** 列表分页数据 */
				pageData: [],

				// 审核商品 表单
				goodsAuditForm: {
					comment: '',
					ca_status: 2
				},
				// 审核商品 表单校验
				goodsAuditRules: {
					comment: [{ required: false, message: '请输入审核备注！', trigger: 'blur' }]
				},
				//添加编辑供应商验证
				gooddataRules: {
					comment: [{ required: true, message: '请输入审核备注！', trigger: 'change' }]
				},
				// 发送站内信 dialog
				dialogGoodsAuditVisible: false,

				//添加供应商dialog
				dialogGood: false,
			}
		},
		activated() {
			this.GET_AccountList()
		},
		mounted() {
			this.GET_AccountList()
			console.log(this.$route.params,'this.$route.params.id')
		},
		methods: {
			/** 分页大小发生改变 */
			handlePageSizeChange(size) {
				this.params.page_size = size
				this.GET_AccountList()
			},

			/** 分页页数发生改变 */
			handlePageCurrentChange(page) {
				this.params.page_no = page
				this.GET_AccountList()
			},
			//获取列表
			GET_AccountList() {
				this.loading = true
				API_shop.retentionMoneyLog(this.params).then(response => {
					console.log(response)
					this.loading = false
					this.tableData = response
				})
			},
			submitGoodsAuditForm(){
				API_Shop.addShop(this.gooddata).then( res=>{

				})
			},
			//账户状态改变
			zhchange(v){
				console.log(v)
			},
			//删除店铺
			desupplier(row){
				this.$confirm('确定要删除这个店铺吗？', '提示', { type: 'warning' }).then(() => {
					API_Shop.closeShop(row.shop_id).then(response => {
						this.$message.success('关闭成功！')
						this.GET_ShopList()
					})
				}).catch(() => {})
			}
		}
	}
</script>

<style lang="scss" scoped>
	::v-deep div.toolbar {
		height: 110px;
		padding: 20px 0;
	}

	::v-deep .el-table {
		width: 100%;
		overflow: auto;
		& td:not(.is-left) {
			text-align: center;
		}
	}

	.inner-toolbar {
		// display: flex;
		// flex-direction: row;
		// flex-wrap: nowrap;
		width: 100%;
		justify-content: space-between;
		align-items: center;
	}

	::v-deep .pop-sku {
		.toolbar {
			display: none;
		}
		.el-dialog__body {
			.el-table {
				border: 1px solid #e5e5e5;
			}
		}
		.el-table__body-wrapper {
			max-height: 400px;
			overflow-y: scroll;
		}
	}


	.toolbar-search {
		margin-right: 10px;
		width: 20%;
		text-align: right;
	}

	div.toolbar-btns {
		// display: flex;
		// flex-direction: row;
		// flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
		div {
			span {
				display: inline-block;
				font-size: 14px;
				color: #606266;
			}
		}
		.conditions {
			// display: flex;
			// flex-direction: row;
			// flex-wrap: nowrap;
			// justify-content: flex-start;
			align-items: center;
			min-width: 18.5%;
			.choose-machine {
				width: 60%;
			}
		}
	}

	.goods-image {
		width: 50px;
		height: 50px;
	}

	::v-deep div.cell {
		overflow:hidden;

		text-overflow:ellipsis;

		display:-webkit-box;

		-webkit-box-orient:vertical;

		-webkit-line-clamp:2;
	}
	/*下架原因*/
	.under-reason {
		color: red;
		cursor: pointer;
	}

</style>
<style scoped>
	.toolbar-btns{}
	.conditions{display: inline-block;}
</style>
